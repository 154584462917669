// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-faucet-mp-js": () => import("./../../../src/pages/account/faucet_mp.js" /* webpackChunkName: "component---src-pages-account-faucet-mp-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-faucet-js": () => import("./../../../src/pages/faucet.js" /* webpackChunkName: "component---src-pages-faucet-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-monacard-yoko-js": () => import("./../../../src/pages/monacard_yoko.js" /* webpackChunkName: "component---src-pages-monacard-yoko-js" */),
  "component---src-pages-mpurse-js": () => import("./../../../src/pages/mpurse.js" /* webpackChunkName: "component---src-pages-mpurse-js" */),
  "component---src-pages-xmp-js": () => import("./../../../src/pages/xmp.js" /* webpackChunkName: "component---src-pages-xmp-js" */)
}

